var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "test-modals" },
    [
      _c("h1", [_vm._v("Row Layout")]),
      _c("div", { staticClass: "flex-row" }, [
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.$bvModal.show("modal-small")
              }
            }
          },
          [_vm._v("Small Modal")]
        ),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.$bvModal.show("modal-medium")
              }
            }
          },
          [_vm._v("Medium Modal")]
        ),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.$bvModal.show("modal-large")
              }
            }
          },
          [_vm._v("Large Modal")]
        ),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.$bvModal.show("modal-xl")
              }
            }
          },
          [_vm._v("XL Modal")]
        ),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.$bvModal.show("modal-md-no-footer")
              }
            }
          },
          [_vm._v("Medium Modal - No Footer")]
        ),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.$bvModal.show("modal-md-custom-footer")
              }
            }
          },
          [_vm._v("Medium Modal - Custom Footer")]
        )
      ]),
      _c(
        "b-modal",
        { attrs: { id: "modal-small", size: "sm", title: "Small Modal" } },
        [_vm._v(" Small Modal ")]
      ),
      _c(
        "b-modal",
        { attrs: { id: "modal-medium", size: "md", title: "Medium Modal" } },
        [_vm._v(" medium Modal ")]
      ),
      _c(
        "b-modal",
        { attrs: { id: "modal-large", size: "lg", title: "large Modal" } },
        [_vm._v(" large Modal ")]
      ),
      _c(
        "b-modal",
        { attrs: { id: "modal-xl", size: "xl", title: "XL Modal" } },
        [_vm._v(" xl Modal ")]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-md-no-footer",
            size: "md",
            "hide-footer": "",
            centered: "",
            title: "No Footer Modal"
          }
        },
        [_vm._v(" medium Modal ")]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-md-custom-footer",
            size: "md",
            "hide-footer": "",
            centered: "",
            title: "Custom Footer Modal"
          }
        },
        [
          _vm._v(" medium Modal "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                on: {
                  click: function($event) {
                    return _vm.$bvModal.hide("modal-md-custom-footer")
                  }
                }
              },
              [_vm._v("Close")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }