<template>
  <div class="test-modals">
    <h1>Row Layout</h1>
    <div class="flex-row">
      <button @click="$bvModal.show('modal-small')">Small Modal</button>
      <button @click="$bvModal.show('modal-medium')">Medium Modal</button>
      <button @click="$bvModal.show('modal-large')">Large Modal</button>
      <button @click="$bvModal.show('modal-xl')">XL Modal</button>

      <button @click="$bvModal.show('modal-md-no-footer')">Medium Modal - No Footer</button>
      <button @click="$bvModal.show('modal-md-custom-footer')">Medium Modal - Custom Footer</button>
    </div>

    <b-modal id="modal-small" size="sm" title="Small Modal">
      Small Modal
    </b-modal>
    <b-modal id="modal-medium" size="md" title="Medium Modal">
      medium Modal
    </b-modal>
    <b-modal id="modal-large" size="lg" title="large Modal">
      large Modal
    </b-modal>
    <b-modal id="modal-xl" size="xl" title="XL Modal">
      xl Modal
    </b-modal>
    <b-modal id="modal-md-no-footer" size="md" hide-footer centered title="No Footer Modal">
      medium Modal
    </b-modal>
    <b-modal id="modal-md-custom-footer" size="md" hide-footer centered title="Custom Footer Modal">
      medium Modal
      <div class="modal-footer">
        <button @click="$bvModal.hide('modal-md-custom-footer')">Close</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
/***
 * Test Page for Modals
 *

 */

export default {
  name: 'test-modals-view',
  components: {
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.test-forms {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: auto;
}

.flex-column {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

label {
  padding: 5px 5px 5px 5px;
  color: #38d429;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 17px;
  font-weight: bold;
  text-align: left;
}

.custom-select {
  color-scheme: dark;
  border-radius: 5px;
  background-color: $form-control-background;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  border-width: 0px 0px 1px;
  border-color: #000 #000 #ababab;
  color: white;
  width: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
}

.custom-select:disabled {
  color: white;
  background: #7d7d7d;
}

select option {
  background: $form-control-background;
  color: $text-color-invert;
}

select option:disabled {
  background: rgba(0,0,0,0.2);
  font-style: italic;
}

select option:checked {
  background: $theme-color-primary-6;
}

option:hover {
  background-color: $theme-color-primary-6;
}

select optgroup {
  color: $text-color-invert;
}

input {
  display: block;
  width: auto;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 0;
  color: $text-color-invert;
  vertical-align: middle;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-color: #000 #000 #ababab;
  border-radius: 5px;
  color-scheme: dark;
  background-color: rgba(0,0,0, 0.25);
}

input:focus {
  border-color: #9aea92;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 212, 41, 0.25);
}

input::placeholder {
  font-style: italic;
  color: rgba(150, 150, 150, 1);
}

input:disabled {
  color: white;
  background: #7d7d7d;
}

input:disabled::placeholder {
  color: white;
}

i {
  // Note this is in px to match the calculated height of Form elements
  font-size: 38px;
  color: $theme-color-primary-3
}

/***
  Button Styles
 */

button {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 3px 20px;
  border: $theme-color-primary-5 2px solid;
  border-radius: 20px;
  background-color: transparent;
  color: $text-color-invert;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

button:hover {
  color: white;
  background-color: rgba(0,0,0,0.15);
  border-color: $theme-color-primary-2;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 212, 41, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

button:active {
  border-color: #9aea92;
  background-color: rgba(150,150,150,0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

/***
  DANGER Button
 */

button.danger {
  background-color: rgba($danger, 0.5);
  border-color: rgba($danger, 0.75);
}

button.danger:hover {
  background-color: rgba($danger, 0.75);
}

button.danger:focus {
  box-shadow: 0 0 0 0.2rem rgba($danger, 0.25);
}

button.danger:active {
  background-color: rgba($danger, 0.9);
  border-color: rgba($danger, 1);
}

/***
  WARNING Button
 */
button.warning {
  background-color: rgba($warning, 0.5);
  border-color: rgba($warning, 0.75);
  color: black;
}

button.warning:hover {
  background-color: rgba($warning, 0.75);
}

button.warning:focus {
  box-shadow: 0 0 0 0.2rem rgba($warning, 0.25);
}

button.warning:active {
  background-color: rgba($warning, 0.9);
  border-color: $warning;
}

/***
  Success Button
 */
button.success {
  background-color: rgba($success, 0.5);
  border-color: rgba($success, 0.75);
}

button.success:hover {
  background-color: rgba($success, 0.75);
  border-color: rgba($success, 0.75);
}

button.success:focus {
  box-shadow: 0 0 0 0.2rem rgba($success, 0.25);
}

button.success:active {
  background-color: rgba($success, 0.9);
  border-color: $success;
}


// Bootstrap Tabs Override



</style>
